<template>
    <div class="elem-table-box">
        <!-- 分页table -->
        <ElemTable
            ref="elem_table"
            :tableApi="tableApi"
            :keys="keys"
            :columns="table_columns"
            height="100%"
            :pathRules="pathRules"
            :isIndex="isIndex"
            :isRadio="isRadio"
            :isSelector="isSelector"
            :method="method"
            :data="data"
            :processData="processData"
            :processPageConfig="processPageConfig"
            :dataList="dataList"
            :selected="selected"
            @on-selector="onChangeSelection($event.value)"
            @on-load="$emit('on-load', $event)"
        >
            <template v-for="item in slots" v-slot:[item]="params">
                <slot :name="item" :row="params.row" :col="params.col" :index="params.index" :event="{ delete: onDelete }"></slot>
            </template>
        </ElemTable>
        <!-- 表单组件 -->
        <component ref="data_form" v-if="formComponent" v-bind:is="formComponent" @on-submit="refresh" @on-reset="refresh"></component>
    </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable"
import ElemTable from "../../communitymobilization/components/ElemTable.vue"

export default {
    components: { ElemTable, LiefengTable },

    data() {
        return {
            tableData: [],
            //分页参数
            pageForm: {
                tableData: [],
                page: Number(this.$core.getUrlParam("page") || 1),
                pageSize: Number(this.$core.getUrlParam("size") || 20),
                total: 0,
            },
            // 加载属性
            loading: false,
            // 表格列属性
            table_columns: [],
            // 已选
            selection: [],
            // 合计数据
            summaryData: null,
            // 隐藏分页控件
            hidePage: false,
            slots: [],
        }
    },

    props: {
        title: {
            type: String,
            required: false,
        },
        // 是否单选
        isRadio: {
            type: Boolean,
            default: false,
        },
        // 显示序号
        isIndex: {
            type: Boolean,
            default: false,
        },
        columns: Array,
        tableApi: String,
        // 表格数据
        tableData: Array,
        // 高度
        height: {
            type: String,
            required: false,
        },
        deleteApi: {
            type: Function,
            required: false,
        },
        editKey: {
            type: String,
            required: false,
        },
        operate: {
            type: Function,
            required: false,
        },
        // 表单组件
        formComponent: {
            type: Object,
            required: false,
        },
        // 修改按钮名称
        editButton: {
            type: String,
            default: "修改",
        },
        // 分页参数 key 值名称
        keys: {
            type: Object,
            default: new Object(null),
        },
        // 是否开启选择器功能
        isSelector: {
            type: Boolean,
            default: false,
        },
        // 显示合计
        summary: {
            type: Boolean,
            default: false,
        },
        // 初始化数据
        data: {
            type: Object,
            default: () => ({}),
        },
        // 请求方法
        method: {
            type: String,
            default: "get",
        },
        // 处理分页配置
        processPageConfig: {
            type: Function,
            required: false,
        },
        // 接口数据
        data: null,
        // 数据处理
        processData: {
            type: Function,
            required: false,
        },
        // 数据列表
        dataList: {
            type: Array,
            required: false,
        },
        // 使用路径规则
        pathRules: {
            type: Boolean,
            default: true,
        },
        // 已选事件
        selected: {
            type: Function,
            required: false,
        },
    },

    watch: {
        selection(v) {
            this.$emit("on-change-selection", {
                tag: "ElemTable",
                value: v,
            })
        },

        columns: {
            handler(v) {
                if (!v) return

                const tc = [].concat(v)

                if (this.isSelection) {
                    tc.unshift({
                        type: "selection",
                        width: 60,
                        align: "center",
                        fixed: "left",
                    })
                }

                const operate = this.getOperate()
                // 是否存在操作列表
                operate && tc.push(operate)
                // 赋值表格配置
                this.table_columns = tc
                this.slots = v ? v.filter(i => i.slot).map(i => i.slot) : []
            },
            immediate: true,
        },

        tableData(v) {
            // 处理合计
            this.summary && this.processSummary(v)
            // 触发事件
            this.$emit("on-load", {
                tag: "ElemTable",
                value: v,
            })
        },
    },

    created() {
        // const _this = this
        // this.requestPage = new RequestPage(this.tableApi, {
        //     method: this.method,
        //     json: this.method === "post",
        //     type: "block",
        //     page: Number(this.$core.getUrlParam("page") || 1),
        //     size: Number(this.$core.getUrlParam("size") || 20),
        //     data: this.data,
        //     keys: this.keys,
        //     load: !!this.tableApi,
        //     processPageConfig: this.processPageConfig,
        //     processData: res => {
        //         this.hidePage = !res.page && !res.pageSize
        //     },
        //     onChange(res) {
        //         _this.tableData = res
        //         _this.pageForm.total = this.getTotal()
        //         _this.pageForm.page = this.getPage()
        //         // 当前页面页码写入 url
        //         window.location.href = window.location.href.split("?")[0] + Utils.jsonToParams({ ...Utils.paramsToJson(window.location.href), page: this.getPage(), size: this.getSize() })
        //         // 写入缓存
        //         sessionStorage.setItem("preUrl", window.location.hash.replace(/^#/, ""))
        //     },
        //     onLoadBefore: () => {
        //         this.loading = true
        //     },
        //     onLoadAfter: () => {
        //         this.loading = false
        //     },
        // })
    },

    methods: {
        /**
         * 搜索
         */
        search(val) {
            this.$refs.elem_table.search(val)
        },

        /**
         * 重置
         */
        reset() {
            // 重置表格数据
            this.$refs.elem_table.reset()
        },

        /**
         * 刷新
         */
        refresh() {
            // 刷新表格数据
            this.$refs.elem_table.refresh()
            // 刷新勾选
            this.selection = []
        },

        /**
         * 打开表单页面，ID 可选，传 ID 为编辑模式
         */
        openForm(id, data) {
            this.$refs.data_form && this.$refs.data_form.open(id, data)
        },

        /**
         * 处理操作事件
         */
        getOperate() {
            // 不存在 编辑 && 删除配置 && 操作按钮 时不显示操作菜单
            if (!this.editKey && !this.deleteApi && !this.operate) {
                return
            }

            let width = 0

            if (this.deleteApi) width += 100
            if (this.editKey) width += 100
            if (this.operate) width += 100

            return {
                title: "操作",
                align: "center",
                float: "right",
                minWidth: width,
                render: (h, params) => {
                    var list

                    // 是否存在自定义操作事件
                    if (this.operate) {
                        const cop = this.operate(h, params)

                        if (cop instanceof Array) {
                            list = cop
                        } else {
                            return cop
                        }
                    }

                    return h(
                        "div",
                        [].concat(
                            ...this.getBasicsOperate(h, params),
                            list && list.length
                                ? h(
                                      "Dropdown",
                                      {
                                          props: {
                                              transfer: true,
                                          },
                                      },
                                      [
                                          h(
                                              "Button",
                                              {
                                                  props: {
                                                      type: "success",
                                                      size: "small",
                                                      icon: "ios-arrow-down",
                                                  },
                                                  style: "margin:0 3px",
                                              },
                                              "更多"
                                          ),
                                          h(
                                              "DropdownMenu",
                                              {
                                                  slot: "list",
                                              },
                                              list
                                          ),
                                      ]
                                  )
                                : null
                        )
                    )
                },
            }
        },

        getBasicsOperate(h, params) {
            let list = []

            // 是否存在删除接口
            if (this.deleteApi) {
                list.unshift(
                    h(
                        "Button",
                        {
                            props: {
                                type: "warning",
                                size: "small",
                            },
                            style: "margin:0 3px",
                            nativeOn: {
                                click: () => {
                                    this.deleteRow(this.deleteApi, params.row)
                                },
                            },
                        },
                        "删除"
                    )
                )
            }

            // 是否存在编辑对应的 Key 属性
            if (this.editKey) {
                list.unshift(
                    h(
                        "Button",
                        {
                            props: {
                                type: "info",
                                size: "small",
                            },
                            style: "margin:0 3px",
                            nativeOn: {
                                click: () => {
                                    this.openForm(params.row[this.editKey], params.row)
                                },
                            },
                        },
                        this.editButton
                    )
                )
            }

            return list
        },

        /**
         * 删除数据
         */
        async deleteRow(f, p) {
            this.$Modal.confirm({
                title: "删除确定",
                content: "您正在删除数据，是否继续？",
                onOk: () => {
                    this.$post(typeof f === "function" ? f(p) : `${f}?${this.editKey}=${p[this.editKey]}`).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            // 刷新表格数据
                            this.$refs.elem_table.refresh()
                        } else {
                            this.$Message.error({
                                content: res.desc || "删除失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },

        /**
         * 处理合计
         */
        processSummary(data) {
            const columns = this.columns
            const sums = {}
            columns.forEach((column, index) => {
                const key = column.key
                if (!key && index !== 0) return (column.key = this.$core.randomString())
                const values = data.map(item => Number(item[key]))
                if (!values.every(value => isNaN(value))) {
                    const v = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    sums[key] = {
                        key,
                        value: v,
                    }
                } else {
                    sums[key] = {
                        key,
                        value: "",
                    }
                }
            })

            sums["undefined"] = {
                value: "合计",
            }

            this.summaryData = sums
        },

        onChangeSelection(v) {
            this.selection = v
        },

        export() {
            this.$refs.elem_table.export()
        },

        resize() {
            this.$refs.liefeng_table.resize()
        },

        /**
         * 清空勾选
         */
        clearSelecteds() {
            this.$refs.elem_table.clearSelecteds()
        },

        keyword(t) {
            this.$refs.elem_table.keyword(t)
        },

        getSelection() {
            return this.selection
        },

        getDataList() {
            return this.$refs.elem_table.getDataList()
        },
    },
}
</script>

<style lang="less">
.elem-table-box {
    height: 100%;

    .liefeng-table {
        position: relative;
        height: 100%;
    }

    .ivu-table-with-summary .ivu-table-tbody tr:last-child td {
        border-bottom: 1px solid #e8eaec;
    }
}

input[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    -moz-appearance: textfield;
}
</style>
