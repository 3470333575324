<template>
    <div>
        <div class="elem-org-base">
            <div class="elem-org-box" v-for="(item, idx) in addrs" :key="item.key">
                <input ref="input" autocomplete="new-password" :maxlength="max" type="text" class="input" :verify="verify" :title="title" :required="required" :name="name" :disabled="disabled || !manual" :placeholder="placeholder" :value="item.value" @change="onChange" @input="onInput" />
                <div class="select-btn" @click="!disabled && onDisplaySelectAddr(idx)" :style="{ cursor: disabled ? 'no-drop' : 'pointer' }">选择地址</div>
                <div class="operate-btn" v-if="idx === addrs.length - 1" @click="!disabled && onAddItem()" :style="{ cursor: disabled ? 'no-drop' : 'pointer' }">
                    <Icon class="icon" type="md-add" color="#fff" />
                </div>
                <div class="operate-btn remove" v-else @click="!disabled && onRemoveItem(idx)" :style="{ cursor: disabled ? 'no-drop' : 'pointer' }">
                    <Icon class="icon" type="md-remove" color="#fff" />
                </div>
            </div>
        </div>

        <CompModal class="addr-model" ref="addr_model" title="选择地址" :onSuccess="onSelectAddr" :onClose="onCloseAddrModal">
            <template>
                <div class="addr-tree-box">
                    <Tree v-if="showTree" :code="code" @handleTree="onChangeTree"></Tree>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import Tree from "@/views/residentdatabase/children/tree.vue"
import Utils from "../utils/utils"

export default {
    components: { CompModal, Tree },

    data() {
        return {
            val: null,

            language: {
                enter: "输入或选择",
                optional: "可选",
            },

            showTree: false,

            addrs: [],
        }
    },

    props: {
        max: {
            type: Number,
            default: 255,
        },
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "请选择",
        },
        value: {
            type: Object,
            default: () => ({}),
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        code: {
            type: String,
            default: "440103001101",
        },
        // 是否可手动输入
        manual: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        value: {
            handler(val) {
                if (!val || !val[this.name]) {
                    return (this.addrs = [{ key: Utils.getUuid() }])
                }

                const arr = []
                var code = this.parse(val.houseCode)
                var as = this.parse(val[this.name])

                if (typeof code === "string") code = [code]
                if (typeof as === "string") as = [as]

                for (let i = 0; i < code?.length; i++) {
                    const c = code[i]

                    if (as.length <= i) {
                        break
                    }

                    arr.push({
                        key: Utils.getUuid(),
                        value: as[i],
                        code: c,
                    })
                }

                this.addrs = arr.length > 0 ? arr : [{ key: Utils.getUuid() }]

                this.setValue()
            },
            immediate: true,
        },
    },

    methods: {
        parse(val) {
            if (typeof val === "string") {
                if (/^[\\{\\[].*[\]\\}]$/.test(val)) {
                    return JSON.parse(val)
                } else if (~val.indexOf("|")) {
                    return val.split("|")
                }
            }
            return val
        },

        onChange(evt) {
            this.$emit("on-change", {
                value: evt.target.value,
                type: "ElemAddr",
                name: this.name,
                set: value => {
                    this.val = value
                },
            })
        },

        onInput(evt) {
            this.$emit("on-input", {
                value: evt.target.value,
                tag: "ElemAddr",
                name: this.name,
                set: value => {
                    this.val = value
                },
            })
        },

        setValue() {
            const as = []
            const codes = []

            for (let i = 0, addrs = this.addrs; i < addrs.length; i++) {
                const v = addrs[i]

                if (v.value && v.code) {
                    as.push(v.value)
                    codes.push(v.code)
                }
            }

            this.value["houseCode"] = codes
            this.value[this.name] = as
        },

        getValue() {
            return this.val
        },

        /**
         * 添加元素
         */
        onAddItem() {
            this.addrs.push({
                key: Utils.getUuid(),
            })
            this.setValue()
        },

        /**
         * 删除元素
         */
        onRemoveItem(idx) {
            this.addrs.splice(idx, 1)
            this.setValue()
        },

        onDisplaySelectAddr(idx) {
            if (!this.showTree) {
                this.showTree = true
            }

            this.current_idx = idx

            this.$refs.addr_model.display()
        },

        // 点击树节点
        onChangeTree(val, treeData) {
            this.treeData = treeData
            if (val.length) {
                this.treeAddress = ""
                this.selectItem = val[0]
                this.getCurrentTitle(this.selectItem.id, treeData)
            } else {
                this.selectItem = {}
            }
        },

        getCurrentTitle(id, treeData) {
            for (let k in treeData) {
                if (treeData[k].id == id) {
                    this.treeAddress = (treeData[k].name || treeData[k].orgName) + this.treeAddress
                    if (!treeData[k].parentId) return
                    this.getCurrentTitle(treeData[k].parentId, this.treeData)
                } else if (treeData[k].children && treeData[k].children.length > 0) {
                    this.getCurrentTitle(id, treeData[k].children)
                }
            }
        },

        onSelectAddr() {
            if (!Object.keys(this.selectItem).length) {
                return this.$Message.warning({
                    content: "请选择现住地址",
                    background: true,
                })
            } else {
                if (this.selectItem.regionType != "0" && this.selectItem.regionType != "4") {
                    return this.$Message.warning({
                        content: "现住地址请选择到房号",
                        background: true,
                    })
                } else {
                    const addr = this.addrs[this.current_idx]

                    addr.value = this.treeAddress
                    addr.code = this.selectItem.code

                    this.$set(this.addrs, this.current_idx, addr)

                    this.setValue()

                    // 关闭窗口
                    this.$refs.addr_model.close()
                }
            }
        },

        onCloseAddrModal() {
            this.$refs.addr_model.close()
        },
    },
}
</script>

<style lang="less">
@import (reference) "../style/utils.less";

.elem-org-base {
    .elem-org-box {
        position: relative;
        width: 100%;
        height: 40px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        .input {
            position: relative;
            height: 40px;
            padding: 0 150px 0 12px;
            font-size: 14px;
            z-index: 10;
            box-sizing: border-box;
            border-radius: 6px;
            transition: all 0.3s ease;
            background: #fff;
            border: 1px solid #e3e3e3;
            width: 100%;

            &:hover,
            &:focus {
                border-color: #b3b3b3;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
        }

        .select-btn {
            position: absolute;
            top: 7px;
            right: 54px;
            bottom: 7px;
            width: 90px;
            background: #2faaf7;
            color: #fff;
            font-size: 13px;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
        }

        .operate-btn {
            position: absolute;
            top: 7px;
            right: 7px;
            bottom: 7px;
            width: 40px;
            background: #2faaf7;
            color: #fff;
            font-size: 20px;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;

            &.remove {
                background: #ff5722;
            }
        }
    }
}

.addr-model {
    .addr-tree-box {
        padding: 10px;
    }
}
</style>
