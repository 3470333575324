<template>
    <div>
        <comp-table title="商城订单" :columns="columns" :search-data="search_data" :keys="{ page: 'currPage', size: 'size' }" :table-api="tableApi" :process-data="onProcessData">
            <template v-slot:search="data">
                <Select v-model="data.search.supplierId" filterable style="width:300px" transfer placeholder="请选择供应商">
                    <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
                </Select>
                <Select v-model="data.search.payStatus" style="width:150px" transfer placeholder="支付状态">
                    <Option value="">全部</Option>
                    <Option :value="0">未支付</Option>
                    <Option :value="2">已支付</Option>
                </Select>
            </template>

            <template #goodsList="evt">
                <div class="goods-box">
                    <div class="item-box" v-for="item in evt.row.goodsList" :key="item.goodsId">
                        <img class="img" :src="item.goodsThumb" />
                        <div class="name" :title="item.goodsName">{{ item.goodsName }}</div>
                    </div>
                </div>
            </template>

            <template #operation="evt">
                <block v-if="evt.row.payStatus == 2 && evt.row.orderStatus == 1 && (evt.row.serviceType.indexOf('service') > -1 || evt.row.serviceType === 'coupon')">
                    <Button size="small" type="success" class="operation-btn" @click="onConfirmation(evt.row)">
                        核销
                    </Button>
                    <Button size="small" type="error" class="operation-btn" @click="onCancleOrder(evt.row)">
                        取消订单
                    </Button>
                </block>
                <block v-else-if="evt.row.payStatus == 2">
                    <Button size="small" type="success" v-if="evt.row.orderStatus == 0" class="operation-btn" @click="onConfirmOrder(evt.row)">
                        确认订单
                    </Button>
                    <Button size="small" type="success" v-if="serviceType === 'goods' && evt.row.orderStatus == 1 && evt.row.shippingStatus == 0" class="operation-btn" @click="evt.row.isShipping === 1 ? onShipments(evt.row) : onNoticeShipments(evt.row)">
                        {{ evt.row.isShipping === 1 ? "发货处理" : "通知取货" }}
                    </Button>

                    <Button size="small" type="success" v-if="(evt.row.orderStatus == 1 || evt.row.orderStatus == 5) && (evt.row.shippingStatus == 1 || evt.row.shippingStatus == 3 || evt.row.shippingStatus == 5)" class="operation-btn" @click="onConfirmShipOrder(evt.row)">
                        收货确认
                    </Button>
                    <Button size="small" type="warning" v-if="evt.row.orderStatus == 4" class="operation-btn" @click="onCancleOrder(evt.row)">
                        退款/售后
                    </Button>

                    <Button size="small" type="error" v-if="evt.row.orderStatus != 2 && evt.row.shippingStatus == 0 && evt.row.orderStatus != 7" class="operation-btn" @click="onCancleOrder(evt.row)">
                        取消订单
                    </Button>
                </block>
                <!-- <Button class="operation-btn" size="small" @click="handleEdit(evt.row)">编辑</Button>
            <Button class="operation-btn" size="small" @click="handleDelete(evt.row)">删除</Button> -->
            </template>
        </comp-table>

        <CompForm title="取消订单" height="auto" :forms="cancel_form" ref="cancel_form_page" @on-submit="onSubmitCancel"></CompForm>
        <CompForm title="发货" height="auto" :forms="shipments_form" ref="shipments_form_page" @on-submit="onSubmitShipments"></CompForm>
    </div>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import CompForm from "../../jointly/components/CompForm.vue"
import Request from "../../jointly/utils/request"

export default {
    components: { CompTable, CompForm },

    data() {
        return {
            // 供应商列表
            suppliers: null,
            // 表格接口
            tableApi: `/gateway/apps/o2o/api/o2o/admin/listAllOrderBySupplierID?oemCode=${parent.vue.oemInfo.oemCode}`,

            // 默认搜索条件
            search_data: {
                payStatus: 2,
            },

            columns: [
                {
                    title: "订单ID",
                    key: "orderId",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "订单状态",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return this.getStatusName(params.row)
                    },
                },
                {
                    title: "支付状态",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return `<span style="color: ${params.row.payStatus == 2 ? "green" : "red"}">${["未支付", "支付确认中", "已支付"][params.row.payStatus] || "其他"}</span>`
                    },
                },
                {
                    title: "订单金额",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return `￥${params.row.orderAmount?.toFixed?.(2)}`
                    },
                },
                {
                    title: "商家名称",
                    key: "shopName",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "商品列表",
                    minWidth: 150,
                    align: "center",
                    slot: "goodsList",
                },
                {
                    title: "下单时间",
                    minWidth: 230,
                    align: "center",
                    render: (h, params) => {
                        return this.$core.formatDate(new Date(params.row.addTime * 1000), "yyyy-MM-dd hh:mm:ss")
                    },
                },
                {
                    title: "支付时间",
                    minWidth: 230,
                    align: "center",
                    render: (h, params) => {
                        return params.row.payTime ? this.$core.formatDate(new Date(params.row.payTime * 1000), "yyyy-MM-dd hh:mm:ss") : "-"
                    },
                },
                {
                    title: "操作",
                    fixed: "right",
                    minWidth: 150,
                    align: "center",
                    slot: "operation",
                },
            ],

            cancel_form: [
                {
                    type: "textarea",
                    title: "取消原因",
                    name: "reason",
                    required: true,
                },
            ],

            shipments_form: [
                {
                    type: "input",
                    title: "快递单号",
                    name: "number",
                    required: true,
                },
            ],
        }
    },

    mounted() {
        this.getSupplierList()
    },

    methods: {
        onProcessData(data) {
            data.dataList.map(item => {
                item.serviceType = item.goodsList[0]?.serviceType
                return item
            })

            return data
        },

        getSupplierList() {
            this.$get("/gateway/apps/o2o/api/o2o/admin/listSupplierOptions").then(res => {
                if (res.code == 200) {
                    // 供应商列表
                    this.suppliers = res.dataList
                }
            })
        },

        /**
         * 核销
         */
        onConfirmation(row) {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "确定核销吗？",
                onOk: () => {
                    Request.post("/gateway/apps/o2o/api/o2o/admin/confirmVerificationSupplier", {
                        supplierId: row.supplierId,
                        orderId: row.orderId,
                        custGlobalId: row.userId,
                    }).then(() => {
                        this.$Message.success("核销成功")
                        // 待评价状态
                        row.orderStatus = 5
                    })
                },
            })
        },

        /**
         * 确认订单
         */
        onConfirmOrder(row) {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "确定确认订单吗？",
                onOk: () => {
                    Request.post("/gateway/apps/o2o/api/o2o/admin/modifyOrderStatus", {
                        orderId: orderId,
                        oemCode: this.$core.getOemCode(),
                        orderStatus: 1,
                    }).then(() => {
                        this.$Message.success("确认订单成功")
                        row.orderStatus = 1
                    })
                },
            })
        },

        /**
         * 取消订单
         */
        onCancleOrder(row) {
            this.ac_order = row
            this.$refs.cancel_form_page.display()
        },

        /**
         * 提交取消订单
         */
        async onSubmitCancel(evt) {
            const order = this.ac_order
            const { goodsList } = order
            const isShip = order.shippingStatus !== 0

            for (let i = 0; i < goodsList.length; i++) {
                await this.onRequestRefund(order, goodsList[i], evt.value.reason, isShip)

                // 循环最后一个商品时
                if (i === goodsList.length - 1) {
                    this.$Message.success("取消订单成功")
                    // 刷新
                    this.$refs.table.reset()
                }
            }
        },

        /**
         * 申请退货/退款
         * @param {object} v 订单实体
         */
        onRequestRefund(order, shop, desc, isShip) {
            return new Promise(resolve => {
                Request.post("/gateway/apps/o2o/api/o2o/order/createBackOrder", {
                    orderId: order.orderId,
                    orderSn: order.orderSn,
                    refundDesc: desc,
                    refundType: isShip ? 2 : 1,
                    goodsId: shop.goodsId,
                    goodsName: shop.goodsName,
                    goodsNumber: shop.goodsNumber || 1,
                    goodsSpId: shop.goodsSpId,
                    refundMoney: order.orderAmount,
                    custGlobalId: this.$core.getUserInfo().custGlobalId,
                    oemCode: parent.vue.oemInfo.oemCode,
                    serviceType: shop.serviceType,
                    supplierId: order.supplierId,
                }).then(() => {
                    resolve()
                })
            })
        },

        /**
         * 确认收货
         */
        onConfirmShipOrder(row) {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "是否确认收货吗？",
                onOk: () => {
                    Request.post("/gateway/apps/o2o/api/o2o/admin/modifyShippingStatus", {
                        orderId: row.orderId,
                        custGlobalId: this.$core.getUserInfo().custGlobalId,
                        oemCode: parent.vue.oemInfo.oemCode,
                        shippingStatus: 2,
                    }).then(() => {
                        this.$Message.success("确认收货成功")
                        // 确认收货状态
                        row.shippingStatus = 2
                    })
                },
            })
        },

        /**
         * 发货处理
         */
        onShipments(row) {
            this.ac_order = row
            this.$refs.shipments_form_page.display()
        },

        /**
         * 提交发货
         */
        async onSubmitShipments(evt) {
            const order = this.ac_order

            Request.post("/gateway/apps/o2o/api/o2o/admin/modifyShippingStatus", {
                orderId: order.orderId,
                custGlobalId: this.$core.getUserInfo().custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                shippingStatus: 1,
                invoiceNo: evt.value.number,
            }).then(() => {
                this.$Message.success("发货成功")
                // 已发货状态
                order.shippingStatus = 1
            })
        },

        /**
         * 通知取货
         */
        onNoticeShipments(row) {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "是否通知取货？",
                onOk: () => {
                    Request.post("/gateway/apps/o2o/api/o2o/admin/modifyShippingStatus", {
                        orderId: row.orderId,
                        custGlobalId: this.$core.getUserInfo().custGlobalId,
                        oemCode: parent.vue.oemInfo.oemCode,
                        shippingStatus: 1,
                    }).then(() => {
                        this.$Message.success("通知取货成功")
                        // 发货中状态
                        row.shippingStatus = 1
                    })
                },
            })
        },

        getStatusName(v = {}) {
            // 存在退货ID，查询退货状态
            if (v.backId) {
                return this.getRefundName(v)
            }

            switch (v.orderStatus) {
                case 2:
                    return "已取消"
                case 5:
                    return "待评价"
                case 6:
                    return "已完成"
                case 7:
                    return "已删除"
            }

            const ps = { 0: "未支付", 1: "支付确认中" }[v.payStatus]

            if (ps) return ps

            if (!~[1, 5].indexOf(v.orderStatus)) {
                return { 0: "未接单", 3: "无效订单", 4: "已退货" }[v.orderStatus]
            }

            if (v.goodsList[0]?.serviceType?.indexOf("service") > -1) {
                return v.shippingStatus == 2 ? "完成" : "待服务"
            }

            if (v.goodsList[0]?.serviceType === "coupon") {
                return v.shippingStatus == 2 ? "已使用" : "未使用"
            }

            if (v.isShipping === 0) {
                return "待确认"
            } else if (v.isShipping === 1) {
                return { 0: "未发货", 1: "已发货", 2: "已完成", 3: "备货中", 5: "发货中", 6: "待提货" }[v.shippingStatus]
            } else if (v.isShipping === 2) {
                return { 0: "备货中", 2: "已完成", 3: "备货中", 5: "发货中" }[v.shippingStatus] || "待提货"
            }

            return "未知"
        },
    },
}
</script>
<style lang="less" scope>
.goods-box {
    display: flex;

    .item-box {
        position: relative;
        margin: 5px;

        .img {
            width: 40px;
            height: 40px;
            border-radius: 2px;
            overflow: hidden;
        }

        .name {
            width: 100%;
            margin-top: 5px;
            box-sizing: border-box;
            font-size: 10px;
            color: #333;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.operation-btn {
    margin: 3px;
}
</style>
