<template>
    <div class="comp-table">
        <!--工具栏-->
        <div class="toolbar" id="toolbar" ref="toolbar" v-if="isToolbar">
            <!--标题-->
            <div class="title-box">
                <Icon type="md-arrow-round-back" v-if="Route.isBack()" @click="Route.back()" size="24" />
                <Icon type="md-home" v-else @click="Route.back()" size="20" />
                <div style="padding: 0 6px; font-size: 16px; white-space: nowrap; cursor: pointer" ref="title" @click="Route.back()">
                    {{ $core.getUrlParam("menuName") || title }}
                </div>
            </div>

            <div class="tool-box">
                <div class="button-box">
                    <!-- 操作按钮模板 -->
                    <slot name="operate"></slot>
                    <!-- 新增按钮 -->
                    <Button v-if="!!formComponent && isCreate" icon="ios-add" type="primary" @click="openForm()">新增</Button>
                </div>
                <slot name="custom"></slot>
            </div>
        </div>
        <div class="content-box">
            <div class="search-box">
                <!-- 搜索组件表单 -->
                <Form ref="search_form" :model="search_data" :label-colon="true" :inline="true" class="search">
                    <slot name="search" :search="search_data"></slot>
                </Form>
                <!-- 搜索操作按钮：存在搜索条件时 -->
                <Button class="btn" v-if="isSearch && searchBtn" type="primary" icon="ios-search" @click="onSearch">查询</Button>
                <Button class="btn" v-if="isSearch && searchBtn" type="success" icon="ios-refresh" @click="onReset">重置</Button>
            </div>
            <Alert class="error-box" type="error" show-icon v-if="error_msg">{{ error_msg }}</Alert>
            <slot></slot>
            <div class="table-box" v-if="loaded">
                <!-- 表格组件 -->
                <elem-table ref="elem_table" :data="search_data" v-bind="_props" @on-change-selection="onChangeSelection" @on-load="$emit('on-load', { tag: 'CompTable', value: $event.value })">
                    <template v-for="(item, idx) in slots" v-slot:[item]="params">
                        <slot :name="item" :row="params.row" :col="params.col" :index="params.index" :event="{ delete: onDelete }"></slot>
                    </template>
                </elem-table>
            </div>
        </div>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3.vue"
import ElemTable from "./elem-table.vue"
import Route from "../../communitymobilization/entity/Route"

export default {
    components: { LiefengContent, ElemTable },

    data() {
        return {
            Route: Route,
            isSearch: false,
            // 加载完毕
            loaded: false,
            search_data: {},
            // 错误消息
            error_msg: "",
            // 插槽
            slots: [],
        }
    },

    props: {
        type: {
            type: String,
            default: "table",
        },
        // 是否显示工具栏
        isToolbar: {
            type: Boolean,
            default: true,
        },
        // 是否单选
        isRadio: {
            type: Boolean,
            default: false,
        },
        // 显示序号
        isIndex: {
            type: Boolean,
            default: true,
        },
        // 标题
        title: String,
        // 表格配置
        columns: Array,
        // 表格接口地址
        tableApi: String,
        // 查询参数
        searchData: {
            type: Object,
            default: () => ({}),
        },
        // 是否开启搜索按钮
        searchBtn: {
            type: Boolean,
            default: true,
        },
        // 删除配置
        deleteApi: {
            type: Function,
            required: false,
        },
        // 是否可创建
        isCreate: {
            type: Boolean,
            default: true,
        },
        // 编辑对应的 ID Key
        editKey: {
            type: String,
            required: false,
        },
        // 表格操作栏配置
        operate: {
            type: Function,
            required: false,
        },
        // 表单组件
        formComponent: {
            type: Object,
            required: false,
        },
        // 数据前置事件
        dataBefore: {
            type: Function,
            required: false,
        },
        // 修改按钮名称
        editButton: {
            type: String,
            default: "修改",
        },
        // 分页参数 key 值名称
        keys: {
            type: Object,
            default: new Object(null),
        },
        // 是否开启选择器功能
        isSelector: {
            type: Boolean,
            default: false,
        },
        // 显示合计
        summary: {
            type: Boolean,
            default: false,
        },
        // 请求方法
        method: {
            type: String,
            default: "get",
        },
        // 处理分页配置
        processPageConfig: {
            type: Function,
            required: false,
        },
        // 接口数据
        data: null,
        // 数据处理
        processData: {
            type: Function,
            required: false,
        },
        // 错误提示
        error: {
            type: String,
            required: false,
        },
        // 数据列表
        dataList: {
            type: Array,
            required: false,
        },
        // 使用路径规则
        pathRules: {
            type: Boolean,
            default: true,
        },
        // 已选事件
        selected: {
            type: Function,
            required: false,
        },
    },

    watch: {
        search_data: {
            handler(v) {
                const o = this.old_data
                for (let i = 0, ks = Object.keys(v); i < ks.length; i++) {
                    let k = ks[i]
                    if (v[k] !== o?.[k]) {
                        this.$emit("on-change-search", {
                            tag: "CompTable",
                            value: {
                                key: k,
                                value: v[k],
                            },
                        })
                    }
                }
                this.old_data = Object.assign({}, v)
            },
            deep: true,
        },
        searchData: {
            handler(val) {
                this.search_data = Object.assign({}, val)
                this.cache_data = JSON.parse(JSON.stringify(val))
            },
            immediate: true,
        },
        error: {
            handler(val) {
                this.error_msg = val
            },
            immediate: true,
        },
        columns: {
            handler(val) {
                this.slots = val ? val.filter(i => i.slot).map(i => i.slot) : []
            },
            immediate: true,
        },
    },

    mounted() {
        // 是否存在表单组件，存在即开启搜索功能
        this.isSearch = this.$refs.search_form.$el.childNodes.length > 0

        setTimeout(() => {
            this.loaded = true
        })
    },

    methods: {
        search(k, v, l) {
            if (typeof k === "object") {
                for (let i = 0, ks = Object.keys(k); i < ks.length; i++) {
                    // 写入数据
                    this.$set(this.search_data, ks[i], k[ks[i]])
                }
                // 触发搜索
                ;(v !== false) & this.onSearch()
                // 结束
                return
            }
            // 写入数据
            this.$set(this.search_data, k, v)
            // 触发搜索
            ;(l !== false) & this.onSearch()
        },

        /**
         * 搜索查询
         */
        async onSearch() {
            var data = JSON.parse(JSON.stringify(this.search_data))

            if (this.dataBefore) {
                var cb = this.dataBefore(data)

                if (cb instanceof Promise) {
                    cb = await cb
                }

                // 无返回值时
                cb = cb ?? data

                if (cb === false) return

                data = cb
            }

            this.$refs.elem_table.search(data)

            this.$emit("search", data)
        },

        /**
         * 重置
         */
        onReset() {
            const d = this.search_data
            // 重置数据
            for (let i = 0, ks = Object.keys(d); i < ks.length; i++) {
                let k = ks[i]
                if (typeof this.cache_data[k] !== "undefined") {
                    this.$set(d, k, typeof this.cache_data[k] === "object" ? JSON.parse(JSON.stringify(this.cache_data[k])) : this.cache_data[k])
                } else this.$set(d, k, null)
            }
            // 重置表格组件
            this.$refs.elem_table.reset()
            // 触发事件
            this.$emit("on-reset")
        },

        /**
         * 重置
         */
        reset() {
            this.onReset()
        },

        /**
         * 刷新
         */
        refresh() {
            this.$refs.elem_table.refresh()
        },

        /**
         * 刷新
         */
        onRefresh() {
            this.$refs.elem_table.refresh()
        },

        setSearchData(key, value) {
            this.$set(this.search_data, key, value)
        },

        /**
         * 显示表单页面，ID 可选，传 ID 为编辑模式
         */
        openForm(id, data) {
            this.$refs.elem_table.openForm(id, data)
        },

        onChangeSelection(evt) {
            this.selection = evt.value

            this.$emit("on-change-selection", {
                tag: "CompTable",
                value: evt.value,
            })
        },

        getSelection() {
            return this.selection
        },

        export() {
            this.$refs.elem_table.export()
        },

        resize() {
            this.$refs.elem_table.resize()
        },

        /**
         * 清空勾选
         */
        clearSelecteds() {
            this.$refs.elem_table.clearSelecteds()
        },

        keyword(t) {
            this.$refs.elem_table.keyword(t)
        },

        getDataList() {
            return this.$refs.elem_table.getDataList()
        },
    },
}
</script>

<style lang="less">
.comp-table {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .toolbar {
        position: sticky;
        top: 0;
        background: #fafafa;
        height: 56px;
        width: 100%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid #e9e9e9;
        z-index: 2;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;

        .title-box {
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .tool-box {
            display: flex;
            align-items: center;
        }
    }

    .toolsbarRight {
        align-items: center;

        div,
        button {
            margin: 5px 0 5px 8px !important;
        }
    }

    .content-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex-grow: 1;

        > .table-box {
            flex-grow: 1;
            padding: 0 10px 10px;
            overflow: hidden;
        }
    }

    .search {
        padding-right: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .ivu-form-item {
            margin: 5px 10px 5px 0 !important;
        }
    }

    .search-box {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-shrink: 0;

        .search {
            > div {
                margin: 8px 15px 8px 0 !important;
                width: auto;
                min-width: 200px;
            }
        }

        .btn {
            margin: 5px 10px 5px 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .error-box {
        margin: 0 10px 10px 10px;
    }

    .button-box {
        display: flex;
        align-items: center;

        > div,
        > button {
            margin: 5px 0 5px 5px;
        }
    }
}
</style>
